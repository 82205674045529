import { useState } from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../components';
import { Play, Stop } from '../../icons';
import { TotalTime } from './TotalTime';

function secondsToDhms(seconds) {
  // seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " jour, " : " jours, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " heure, " : " heures, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " seconde" : " secondes") : "0 seconde";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

function TimeItem({ item, handleUpdateItem }) {
  const { label, totalTime } = item;
  const [isRunning, setIsRunning] = useState(false);
  const [timer, setTimer] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const startTimer = () => {
    setIsRunning(true);
    let seconds = 0;
    const intervalId = setInterval(() => {
      seconds = seconds + 1;
      setTimer(seconds);
    }, 1000);
    setTimerId(intervalId);
  };
  const resetTimer = () => {
    setTimerId(null);
    setTimer(0);
  };
  const saveTimeOnItem = () => {
    handleUpdateItem({
      ...item,
      totalTime: item.totalTime + timer,
      records: [
        ...item.records,
        { date: new Date().toISOString(), timer: timer }
      ]
    });
  };
  const handleStopClicking = () => {
    stopTimer();
    saveTimeOnItem();
    resetTimer();
  };
  const stopTimer = () => {
    setIsRunning(false);
    clearInterval(timerId);
    saveTimeOnItem();
  };

  return (
    <ItemWrapper>
      {
        isRunning ?
          (<Button onClick={() => handleStopClicking()} isSmall type="isText"><Stop /></Button>) :
          (<Button onClick={() => startTimer()} isSmall><Play /></Button>)
      }
      <div style={{ marginLeft: 24 }}>
        <Text>{label}</Text>
        <div style={{ display: "flex", marginTop: 8 }}>
          <TotalTime value={secondsToDhms(totalTime)} />
          {isRunning && <Text as="span" style={{ fontSize: 14 }}> - {secondsToDhms(timer)}</Text>}
        </div>
      </div>
    </ItemWrapper >
  )
};
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;
export { TimeItem }