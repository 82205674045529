const theme = {
  blue: {
    _100: "#CFE2FF",
    _200: "#9EC5FE",
    _300: "#6EA8FE",
    _400: "#3D8BFD",
    _500: "#0D6EFD",
    _600: "#0A58CA",
    _700: "#084298",
    _800: "#052C65",
    _900: "#031633",
  },
  indigo: {
    _100: "#E0CFFC",
    _200: "#C29FFA",
    _300: "#A370F7",
    _400: "#8540F5",
    _500: "#6610F2",
    _600: "#520DC2",
    _700: "#3D0A91",
    _800: "#290661",
    _900: "#140330",
  },
  purple: {
    _100: "#E2D9F3",
    _200: "#C5B3E6",
    _300: "#A98EDA",
    _400: "#8C68CD",
    _500: "#6F42C1",
    _600: "#59359A",
    _700: "#432874",
    _800: "#2C1A4D",
    _900: "#160D27",
  },
  pink: {
    _100: "#F7D6E6",
    _200: "#EFADCE",
    _300: "#E685B5",
    _400: "#DE5C9D",
    _500: "#D63384",
    _600: "#AB296A",
    _700: "#801F4F",
    _800: "#561435",
    _900: "#2B0A1A",
  },
  red: {
    _100: "#F8D7DA",
    _200: "#F1AEB5",
    _300: "#EA868F",
    _400: "#E35D6A",
    _500: "#DC3545",
    _600: "#B02A37",
    _700: "#842029",
    _800: "#58151C",
    _900: "#2C0B0E",
  },
  orange: {
    _100: "#FFE5D0",
    _200: "#FECBA1",
    _300: "#FEB272",
    _400: "#FD9843",
    _500: "#FD7E14",
    _600: "#CA6510",
    _700: "#984C0C",
    _800: "#653208",
    _900: "#331904",
  },
  yellow: {
    _100: "#FFF3CD",
    _200: "#FFE69C",
    _300: "#FFDA6A",
    _400: "#FFCD39",
    _500: "#FFC107",
    _600: "#CC9A06",
    _800: "#664D03",
    _900: "#332701",
  },
  green: {
    _100: "#D1E7DD",
    _200: "#A3CFBB",
    _300: "#75B798",
    _400: "#479F76",
    _500: "#198754",
    _600: "#146C43",
    _700: "#0F5132",
    _800: "#0A3622",
    _900: "#051B11",
  },
  teal: {
    _100: "#D2F4EA",
    _200: "#A6E9D5",
    _300: "#79DFC1",
    _400: "#4DD4AC",
    _500: "#20C997",
    _600: "#1AA179",
    _700: "#13795B",
    _800: "#0D503C",
    _900: "#06281E",
  },
  cyan: {
    _100: "#CFF4FC",
    _200: "#9EEAF9",
    _300: "#6EDFF6",
    _400: "#3DD5F3",
    _500: "#0DCAF0",
    _600: "#0AA2C0",
    _700: "#087990",
    _800: "#055160",
    _900: "#032830"
  },
  gray: {
    _100: "#F8F9FA",
    _200: "#E9ECEF",
    _300: "#DEE2E6",
    _400: "#CED4DA",
    _500: "#ADB5BD",
    _600: "#6C757D",
    _700: "#495057",
    _800: "#343A40",
    _900: "#212529"
  },
  interface: {
    button: {
      _500: "#272829"
    }
  }
};


// Convert `obj` to a key/value array
const asArray = Object.entries(theme);

// Remove interface from theme
const filtered = asArray.filter(([key, value]) => {
  return key != 'interface'
});
// Convert the key/value array back to an object:
const themeFiltered = Object.fromEntries(filtered);
// Get options
const options = Object.keys(themeFiltered);

export { theme, options };