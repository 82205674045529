import styled from 'styled-components';

const Text = ({ children, style, as }) => {
  return (
    <TextStyled style={style} as={as}>{children}</TextStyled>
  );
}
const TextStyled = styled.p`
  color: ${props => props.theme._900};
`;

export { Text };