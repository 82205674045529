import styled from 'styled-components';
import { theme } from '../theming';

const Button = ({ children, onClick, type, style, isSmall }) => {
  if (type === 'isDark') {
    return (
      <DarkButton onClick={onClick} style={style} isSmall={isSmall}>{children}</DarkButton>
    )
  }
  if (type === 'isText') {
    return (
      <TextButton onClick={onClick} style={style} isSmall={isSmall}>{children}</TextButton>
    )
  }
  return (
    <Tap onClick={onClick} style={style} isSmall={isSmall}>{children}</Tap>
  )
};

const Tap = styled.button`
  padding: ${props => props.isSmall ? "4px 8px" : "8px 16px"};
  border: none;
  border-radius: 4px;
  background-color: ${props => props.theme._500};
  color: white;
  transition: background-color 200ms ease;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme._600};
  }
`;
const TextButton = styled.button`
  padding: ${props => props.isSmall ? "4px 8px" : "8px 16px"};
  border: none;
  border-radius: 4px;
  background-color: inherit;
  color: ${props => props.theme._600};
  transition: background-color 200ms ease;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme._700};
  }
`;
const DarkButton = styled(Tap)`
  background: ${props => props.theme._900};
  &:hover {
    background: #000;
  }

`;

export { Button };