const Settings = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      d="M12 9.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.168 7.25a2.463 2.463 0 0 0-3.379-.912 1.543 1.543 0 0 1-2.314-1.346A2.484 2.484 0 0 0 12 2.5a2.484 2.484 0 0 0-2.475 2.492 1.543 1.543 0 0 1-2.313 1.347 2.465 2.465 0 0 0-3.38.912 2.5 2.5 0 0 0 .906 3.4 1.56 1.56 0 0 1 0 2.692 2.5 2.5 0 0 0-.272 4.13 2.464 2.464 0 0 0 2.745.183 1.542 1.542 0 0 1 2.313 1.345A2.484 2.484 0 0 0 12 21.5a2.484 2.484 0 0 0 2.474-2.492 1.543 1.543 0 0 1 2.314-1.345 2.463 2.463 0 0 0 3.379-.913 2.5 2.5 0 0 0-.905-3.4 1.56 1.56 0 0 1 0-2.692 2.5 2.5 0 0 0 .906-3.408Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { Settings };