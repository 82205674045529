import styled from 'styled-components';

const Input = ({ value, style, placeholder, onChange, onKeyDown }) => {
  return (
    <InputStyled
      value={value}
      style={styled}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
};
const InputStyled = styled.input`
  border: 1px solid ${props => props.theme._500};
  border-radius: 3px;
  padding: 8px;
  color: ${props => props.theme._800};
  transition: border-color 175ms ease, color 175ms ease ;
  &:focus {
    outline: 0;
    border-color: ${props => props.theme._700};
    &::placeholder {
      color: ${props => props.theme._600};
    }
  }
  &::placeholder {
    color: ${props => props.theme._500};
  }
  &:hover {
    border-color: ${props => props.theme._700};
    &::placeholder {
      color: ${props => props.theme._600};
    }
  }
`;

export { Input };