import styled from 'styled-components';
import { Logo } from '../assets';
import { Settings } from '../icons';

const Header = ({ onClick }) => {
  return (
    <Container>
      <Logo />
      <IconButton onClick={() => onClick()}>
        <Settings />
      </IconButton>

    </Container>
  );
};
const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #000;
  margin-bottom: 36px;
  padding: 0 16px;
  box-sizing: border-box;
`;
const IconButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export { Header };