import { useState, useEffect } from "react";
import { AnimatedLine } from "../../components";

function TotalTime({ value }) {
  const [values, setValues] = useState([value]);
  useEffect(() => {
    setValues([...values, value]);
  }, [value]);

  return <AnimatedLine values={values} fontSize={14} />;
};

export { TotalTime };