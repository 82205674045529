import styled, { keyframes } from 'styled-components';

function AnimatedLine({ values, fontSize }) {
  const _fontSize = fontSize || 36;
  const newValue = values.length - 1;
  const hasNewValues = values.length > 2;

  return (
    <Container>
      <p style={{ color: "transparent", pointerEvents: "none" }}>{values[values.length - 1]}.</p>
      {
        typeof values == 'object' && values.map((value, i) => (
          <Line
            key={i}
            style={{ fontSize: _fontSize }}
            fontSize={_fontSize + "px"}
            className={[
              i != newValue && "out",
              i === newValue && "in",
              hasNewValues && i === newValue && "fadeIn",
              hasNewValues && i !== newValue && "fadeOut",
            ]}>{value}</Line>
        ))
      }
    </Container>
  )
};
const fadeOut = keyframes`
  from {
    top: ${props => `calc(${props.height} / 2 - ${props.fontSize} / 2)`};
    opacity: 1;
  }
  to {
    top: -100%;
    opacity: 0;
  }
`;
const fadeIn = keyframes`
  from {
    top: 100%;
    opacity: 0;
  }
  to {
    top: ${props => `calc(${props.height} / 2 - ${props.fontSize} / 2)`};
    opacity: 1;
  }
`;
const Container = styled.div`
  position: relative;
  height: ${props => props.height};
`;
const Line = styled.p`
  position: absolute;
  top: 0;

  &.fadeIn {
    animation: ${fadeIn} 175ms forwards;
  }
  &.fadeOut {
    animation: ${fadeOut} 175ms forwards;
  }
  &.in {
    top: 0;
    opacity: 1;
  }
  &.out {
    top: 100%;
    opacity: 0;
  }
`;
export { AnimatedLine };