// import styled from 'styled-components';
import { DayChart } from './DayChart';

function dateIsEqual(a, b) {
  return a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
};

function AgendaChart({ records }) {
  const data = records || []
  const today = new Date();
  const end = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
  const dates = [];

  // generate all dates and store them in array
  for (let i = today; !dateIsEqual(i, end); i = new Date(i.getFullYear(), i.getMonth(), i.getDate() - 1)) {
    dates.push(i);
  }

  function getTotalTime(date) {
    const result = data.filter(record => {
      return record.date.slice(0, 10) === date.toISOString().slice(0, 10)
    });
    if (result.length > 0) {
      return result.reduce((accumulator, currentValue) => accumulator + currentValue.timer, 0);
    }
    return 0;
  }

  return (
    <div>
      {/* Mois de l'année */}
      {/* <div style={{ display: "flex" }}>
        <p>Jan</p>
        <p>Fév</p>
        <p>Mar</p>
        <p>Avr</p>
        <p>Mai</p>
        <p>Juin</p>
        <p>Juil</p>
        <p>Août</p>
        <p>Sept</p>
        <p>Oct</p>
        <p>Nov</p>
        <p>Déc</p>
      </div> */}
      {/* Jours de la semaine */}
      <div style={{ display: "flex" }}>
        {/* <div>
          <p>Lun</p>
          <p>Merc</p>
          <p>Ven</p>
        </div> */}
        {/* Graph */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {
            dates.reverse().map((date, i) => {
              const totalTime = getTotalTime(date);
              return (
                <DayChart date={date} key={i} totalTime={totalTime} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
};

export { AgendaChart };