import { useEffect, useState } from 'react';
import { AgendaChart, Button, Header, Input, Text } from './components';
import { TimeItem } from './containers';
import { theme, options } from './theming';
import styled, { ThemeProvider } from 'styled-components';
import { Cross } from './icons';

function ItemsForm({ handleAddItem, itemsLength }) {
  const [hasForm, setHasForm] = useState(false);
  const [input, setInput] = useState("");
  const addItem = () => {
    handleAddItem({ id: itemsLength + 1, label: input, totalTime: 0, records: [] });
    setHasForm(false);
  };
  return (
    <>{
      hasForm ?
        (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ marginBottom: 8, fontWeight: "bold" }}>New task :</Text>
            <Input
              value={input.value}
              onKeyDown={(e) => e.key === "Enter" && addItem()}
              onChange={(e) => setInput(e.target.value)}
              placeholder="New task"
            />
            <div style={{ marginTop: 12 }}>
              <Button onClick={() => addItem()} style={{ marginRight: 12 }} type="isDark">Create</Button>
              <Button onClick={() => setHasForm(false)} type="isText">Cancel</Button>
            </div>
          </div >
        ) :
        (<Button onClick={() => setHasForm(true)} type="isDark">Create a new task</Button>)
    }</>
  )
};
function ItemsList({ items, handleUpdateItem }) {
  return (
    <div style={{ marginBottom: 24 }}>
      {
        items.length > 0 ?
          items.sort((f, s) => f.id - s.id).map(
            item => (
              <TimeItem
                item={item}
                handleUpdateItem={handleUpdateItem}
                key={item.id} />
            )) :
          (<p style={{ fontStyle: "italic" }}>Create your first task to track</p>)
      }
    </div>
  )
};
function GraphItem({ item }) {
  const { label, records, totalTime } = item;
  return (
    <div style={{ marginBottom: 24 }}>
      <p style={{ marginBottom: 8 }}>{label} :</p>
      <AgendaChart records={records} />
    </div>
  )
};
function GraphItemsList({ items }) {
  return (
    <>
      {
        items.length > 0 ?
          items.sort((f, s) => f.id - s.id).map(
            (item, i) => (
              <GraphItem item={item} key={i} />
            )) :
          (<p style={{ fontStyle: "italic" }}>There are no charts to display - Create a new task</p>)
      }
    </>
  )
};
function App() {
  const initialState = {
    theme: options[0],
    items: [], // [{label: "", totalTime: numberInSeconds, records: [ {date, totalTime} ]}]
  };
  const [db, setDB] = useState(JSON.parse(window.localStorage.getItem('db')) || initialState);
  const addItem = (newItem) => {
    setDB({ ...db, items: [...db.items, newItem] });
  };
  const updateDb = (newItem) => {
    const { id } = newItem;
    const items = db.items.filter(item => item.id != id);
    setDB({ ...db, items: [...items, newItem] });
  };
  const updateTheme = (newTheme) => {
    setDB({
      ...db,
      theme: newTheme
    })
  };
  useEffect(() => {
    // persist data to LocalStorage;
    window.localStorage.setItem('db', JSON.stringify(db));
  }, [db]);
  const [isOpen, setIsOpen] = useState(false)
  return (
    <ThemeProvider theme={theme[db.theme]}>
      <Container className={isOpen && 'is-open'}>
        <div>
          <Header onClick={() => setIsOpen(!isOpen)} />
          <Wrapper style={{ marginBottom: 36 }}>
            <Text style={{ marginBottom: 28 }} as="h1">Tasks</Text>
            <Box>
              <ItemsList
                items={db.items}
                handleUpdateItem={(newItem) => updateDb(newItem)}
              />
              <ItemsForm
                itemsLength={db.items.length}
                handleAddItem={(newItem) => addItem(newItem)} />
            </Box>
          </Wrapper>
          <Wrapper>
            <Text style={{ marginBottom: 28 }} as="h1">Charts</Text>
            <GraphItemsList items={db.items} />
          </Wrapper>
        </div>
        <Menu className={isOpen && 'is-open'}>
          <IconButton onClick={() => setIsOpen(!isOpen)} style={{ marginLeft: "auto" }}>
            <Cross />
          </IconButton>
          <Text style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Theming :</Text>
          <select onChange={(e) => updateTheme(e.target.value)} value={db.theme}>
            {
              options.map(element => (
                <option value={element} key={element}>{element}</option>
              ))
            }
          </select>
        </Menu>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  max-width: 100%;
  transition: max-width 175ms ease;
  & >:first-child {
    min-width: 100%;
  }
  &.is-open {
    max-width: calc(100% - 300px);
    overflow: visible;
  }
`;
const IconButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
const Wrapper = styled.div`
margin: 0 auto;
max-width: 500px;
`;
const Box = styled.div`
background-color: ${props => props.theme._100};
border-radius: 15px;
padding: 16px;
`;
const Menu = styled.div`
  box-sizing: border-box;
  background: white;
  padding: 8px 16px;
  width: 300px;
  min-width: 300px;
  z-index: 1;
`;

export default App;
