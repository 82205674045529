import styled, { keyframes } from 'styled-components';

const Circle = () => {
  return (
    <Cir />
  );
};

const Stop = ({ style }) => {
  return (
    <Wrapper>
      <Circle />
      <Icon xmlns="http://www.w3.org/2000/svg" width={24} height={24} style={style}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path fill="currentColor" d="M6 5h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1z" />
      </Icon>
    </Wrapper>
  )
};

const Pulsation = keyframes`
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.5);
  }
`;
const Cir = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${props => props.theme._200};
  width: 24px;
  height: 24px;
  animation: ${Pulsation} 1s infinite ease-out;
`;
const Icon = styled.svg`
  position: absolute;
  z-index: 2;
  left: 0;
`;
const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;
export { Stop };