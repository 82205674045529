import styled from 'styled-components';

function DayChart({ date, totalTime }) {
  return (
    <Container>
      <Carre totalTime={totalTime} />
      <Popup className='popup'>{date.toDateString()} {totalTime}</Popup>
    </Container>
  )
};
const Container = styled.div`
  position: relative;
`;
const Carre = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 3px;
  background: ${props => props.totalTime >= 1000 ?
    props.theme._800 : props.totalTime >= 100 ?
      props.theme._500 : props.totalTime >= 10 ?
        props.theme._300 : props.totalTime >= 1 ?
          props.theme._100 : "#ebedf0"};
  border: 1px solid #dfe1e4;
  margin: 2px;

  &:hover {
    cursor: pointer;
  }

  &:hover ~ .popup {
    display: block;
  }
`;
const Popup = styled.p`
  content: '';
  position: absolute;
  background-color: ${props => props.theme._900};
  color: white;
  display: none;
  z-index: 1;
  padding: 4px 16px;
  border-radius: 3px;
  min-width: 150px;
  left: calc(50% - 50px);
  bottom: calc(100% + 4px);

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 50px + 5px);
    height: 0; 
    width: 0;
    border-top: 4px solid ${props => props.theme._900};;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

export { DayChart };