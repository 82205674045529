const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    >
      <path d="m14.394 9.595-4.792 4.792M14.4 14.393l-4.8-4.8M2.75 12c0 6.937 2.313 9.25 9.25 9.25s9.25-2.313 9.25-9.25S18.937 2.75 12 2.75 2.75 5.063 2.75 12Z" />
    </g>
  </svg>
)

export { Cross };